import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/page.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We put security first, and we strive to implement the best security procedures and practices appropriate to the nature of the information we store, in order to protect it from unauthorized access, destruction, use, modification, or disclosure.`}</p>
    <h3>{`Infrastructure`}</h3>
    <p>{`Mindthedoc is hosted on Google Cloud Platform and AWS. Google and AWS data centers feature extensive safeguards and security layers. You can find more here:`}</p>
    <ul>
      <li parentName="ul">{`Google`}</li>
      <li parentName="ul">{`AWS`}</li>
    </ul>
    <h3>{`High Availability`}</h3>
    <p>{`Every part of Mindthedoc is provisioned to be highly available. We have redundant system in the case of failure.`}</p>
    <h3>{`Data Location`}</h3>
    <p>{`We use a mix of data centers all located in Europe.`}</p>
    <h3>{`Disaster Recovery`}</h3>
    <p>{`Daily backups are generated and saved across multiple regions. In the event of a region-wide outage, we are able to bring up our system in a different region.`}</p>
    <h3>{`Data transmission`}</h3>
    <p>{`Data is sent securely to Mindthedoc via TLS to an HTTPS endpoint. All data is AES-256bit encrypted, both in transit and at rest.`}</p>
    <h3>{`Data Encryption`}</h3>
    <p>{`All data in Mindthedoc servers is encrypted at rest. GCP and AWS store and manage data cryptography keys. So, if an intruder were ever able to access any of the physical storage devices, the Mindthedoc data contained therein would still be impossible to decrypt without the keys.`}</p>
    <h3>{`Data Removal`}</h3>
    <p>{`All customer data stored on Mindthedoc servers is deleted upon a customer’s termination of service and deletion of account in no longer than 30 days.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      